<app-header></app-header>

<div class="container">
    <div class="row">

        <div class="col-6 col-md-6 mt-3">
            <h3 class="h3 text-dark text-left float-left">
                <strong><i class="fas fa-th"></i>&nbsp;&nbsp;Página Inicial</strong>
            </h3>
        </div>

        <div class="col-6 col-md-6 mt-3">
            <a href="#" class="text-dark float-right mt-2">

            </a>
        </div>

        <div class="col-12 col-md-12">
            <hr class="hr-dash">
        </div>

    </div>

    <div class="row">

        <div class="col-12 col-md-4">
            <div class="card shadow">
                <div class="card-body" style="height: 170px;">
                    <h3 class="card-title text-left">Lista de Clientes</h3>
                    <p id="qtd" class="card-text text-right mt-4">{{totalClientes}}</p>
                </div>
                <a [routerLink]="['/clientes']" class="btn btn-sm btn-secondary btn-block"
                    style="color: #fff; cursor: pointer;">
                    Lista de Clientes
                </a>
                <!-- <div class="row no-gutters">
                    <div class="col-md-5 p-3">
                        <img src="assets/img/clientes.svg" width="200" class="card-img" alt="...">
                    </div>
                    <div class="col-12 col-md-2">
                        
                    </div>
                    <a [routerLink]="['/clientes']" class="btn btn-sm btn-secondary btn-block" style="color: #fff; cursor: pointer;">
                        Lista de Clientes
                    </a>
                </div> -->
            </div>
        </div>

        <div class="col-12 col-md-4">
            <div class="card shadow">
                <div class="card-body" style="height: 170px;">
                    <h3 class="card-title text-left">Ordem de Serviços Abertas</h3>
                    <p id="qtd" class="card-text text-right">{{totalOSAbertas}}</p>
                </div>
                <a [routerLink]="['/ordemservico']" class="btn btn-sm btn-secondary btn-block"
                    style="color: #fff; cursor: pointer;">
                    Ordens de Serviços Abertas
                </a>
                <!-- <div class="row no-gutters">
                    <div class="col-md-5 p-3">
                        <img src="assets/img/ordemservico.svg" width="200" class="card-img" alt="...">
                    </div>
                    <div class="col-md-7">
                        
                    </div>
                    
                </div> -->
            </div>
        </div>

        <div class="col-12 col-md-4">
            <div class="card shadow">
                <div class="card-body" style="height: 170px;">
                    <h3 class="card-title text-left">Ordem de Serviços Encerradas</h3>
                    <p id="qtd" class="card-text text-right">{{totalOSEncerradas}}</p>
                </div>
                <a [routerLink]="['/os-encerrada']" class="btn btn-sm btn-secondary btn-block"
                    style="color: #fff; cursor: pointer;">
                    Ordens de Serviços Encerradas
                </a>
                <!-- <div class="row no-gutters">
                    <div class="col-md-5 p-3">
                        <img src="assets/img/ordemservico.svg" width="200" class="card-img" alt="...">
                    </div>
                    <div class="col-md-7">
                        
                    </div>
                    
                </div> -->
            </div>
        </div>

    </div>

</div>