<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6 mt-4 mb-4">
            <img src="assets/img/datallmed.svg" alt="logo-datallmed"
                class="img-fluid mx-auto float-left">
        </div>
        <div class="col-12 col-md-6 mt-4 mb-4">
            <img src="assets/img/allmedhosp.svg" alt="logo-datallmed"
                class="img-fluid mx-auto float-right">
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/home">Datallmed - O.S.</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" routerLink="/home">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Cadastros
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/clientes">Clientes</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="/setor">Setores</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="/tiposclientes">Tipos de Clientes</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" routerLink="/tipoequipamento">Tipos de Equipamentos</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/ordemservico">Ordens de Serviço</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/os-encerrada">O.S. Encerradas</a>
            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
            <button (click)="logout()" class="btn btn-sm btn-danger my-2 my-sm-0" type="button">
                Logout&nbsp;-&nbsp;<i class="fas fa-sign-out-alt"></i>
            </button>
        </form>
    </div>
</nav>