import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "../../environments/environment";

import { OrdemServico } from "../models/ordemservico.model";

@Injectable({ providedIn: "root" })
export class OrdemServicoService {
  private apiPath = `${environment.DATALLMED_API}/ordensservicos`;

  constructor(private _http: HttpClient) {}

  public getAll(): Observable<OrdemServico[]> {
    return this._http.get(this.apiPath).pipe(catchError(this.handleError));
  }
  public getAllClosed(): Observable<OrdemServico[]> {
    return this._http
      .get(`${this.apiPath}/encerradas`)
      .pipe(catchError(this.handleError));
  }

  public getAllOrdemDeServico(
    page?: number,
    limit?: number
  ): Observable<OrdemServico[]> {
    let url = `${this.apiPath}?page=${page}&limit=${limit}`;
    return this._http.get(url).pipe(catchError(this.handleError));
  }

  public getFilter(value?: string): Observable<OrdemServico[]> {
    const params = new HttpParams().append("value", value.toUpperCase());
    return this._http
      .get(`${this.apiPath}/search`, { params })
      .pipe(catchError(this.handleError));
  }

  public getAllOrdemDeServicoEncerrada(
    page?: number,
    limit?: number
  ): Observable<OrdemServico[]> {
    let url = `${this.apiPath}/encerradas?page=${page}&limit=${limit}`;
    return this._http.get(url).pipe(catchError(this.handleError));
  }

  public getFilterClosed(value?: string): Observable<OrdemServico[]> {
    const params = new HttpParams().append("value", value.toUpperCase());
    return this._http
      .get(`${this.apiPath}/encerradas/search`, { params })
      .pipe(catchError(this.handleError));
  }

  public getById(_id: string): Observable<OrdemServico> {
    const url = `${this.apiPath}/${_id}`;
    return this._http
      .get(url)
      .pipe(catchError(this.handleError), map(this.jsonDataToOrdemServico));
  }

  public create(ordemservico: OrdemServico): Observable<OrdemServico> {
    return this._http
      .post(this.apiPath, ordemservico)
      .pipe(catchError(this.handleError), map(this.jsonDataToOrdemServico));
  }

  public update(ordemservico: OrdemServico): Observable<OrdemServico> {
    const url = `${this.apiPath}/${ordemservico._id}`;
    return this._http
      .patch(url, ordemservico)
      .pipe(catchError(this.handleError), map(this.jsonDataToOrdemServico));
  }

  public delete(_id: string): Observable<any> {
    const url = `${this.apiPath}/${_id}`;
    return this._http.delete(url).pipe(
      catchError(this.handleError),
      map(() => null)
    );
  }

  // private jsonDataToOrdensServicos(jsonData: any[]): OrdemServico[] {
  //   const ordensservicos: OrdemServico[] = [];
  //   jsonData.forEach((element) => ordensservicos.push(element as OrdemServico));
  //   return ordensservicos;
  // }

  private jsonDataToOrdemServico(jsonData: any): OrdemServico {
    return jsonData as OrdemServico;
  }

  private handleError(error: any): Observable<any> {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.eror.message;
    } else {
      errorMessage = `Error Code: ${error.status}\n Message: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
