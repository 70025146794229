<div class="container">
    <div class="row">
        <div id="login-panel" class="col-10 offset-1 col-md-6 offset-md-3 shadow">
            <img src="assets/img/datallmed.svg" alt="logo-datallmed" class="mx-auto img-fluid d-block mt-4 mb-3">
            <h4 class="h4 text-center"><strong>Módulo de Ordem de Serviços</strong></h4>
            <form [formGroup]="loginForm" (submit)="onSubmit()">
                <div class="form-row ui-g">
                    <div class="form-group col-12 col-md-12 ui-md-12">
                        <label for="email">E-mail:</label>
                        <input type="email" class="form-control" formControlName="email" />
                    </div>
                    <div class="form-group col-12 col-md-12 ui-md-12">
                        <label for="password">Senha:</label>
                        <input type="password" class="form-control" formControlName="password" />
                    </div>
                    <div class="form-group col-12 col-md-12 ui-md-12">
                        <button type="submit" [disabled]="loading" class="btn btn-primary btn-block shadow">
                            Acessar Sistema
                        </button>
                        <img *ngIf="loading" width="40" height="40" src="assets/img/loading.gif"
                            class="img-fluid mx-auto d-block mt-2 mb-2" alt="carregando...">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>