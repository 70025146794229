import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";

import { registerLocaleData } from "@angular/common";
import ptBR from "@angular/common/locales/pt";

registerLocaleData(ptBR);

import { TableModule } from "primeng/table";

import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";

import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";

import { ErrorInterceptor } from "./helpers/error.interceptor";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
// import { OsEncerradaComponent } from './os-encerrada/os-encerrada.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    // OsEncerradaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    FooterModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      closeButton: true,
      progressAnimation: "decreasing",
      progressBar: true,
      preventDuplicates: true,
    }),
    TableModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
