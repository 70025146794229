import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { OrdemServico } from "../models/ordemservico.model";

import { ToastrService } from "ngx-toastr";

import {
  Canvas,
  Columns,
  Img,
  Line,
  PdfMakeWrapper,
  Table,
  Txt,
  Ul,
} from "pdfmake-wrapper";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import * as moment from "moment";
import "moment/locale/pt-br";

import "datatables.net";
import "datatables.net-bs4";
import { Subject } from "rxjs";
import { OrdemServicoService } from "../ordemservico/ordemservico.service";

@Component({
  selector: "app-os-encerrada",
  templateUrl: "./os-encerrada.component.html",
  styleUrls: ["./os-encerrada.component.css"],
})
export class OsEncerradaComponent implements OnInit {
  ordensservico: OrdemServico[] = [];
  dataTable: any;
  filterDataSource: any[] = [];
  limit: number = 10;
  page: number = 1;
  totalDocs: number;
  totalPages: number;
  pageOptions: number[] = [10, 25, 50];
  loading: boolean = false;
  filter?: string = "";
  displayModal: boolean;
  searchTerms = new Subject<string>();

  constructor(
    private ordemServicoService: OrdemServicoService,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchTerms
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(() => {
        if (this.filter) {
          this.limit = this.totalDocs;
          this.getFilter();
        } else {
          this.limit = 10;
          this.pageOptions = [10, 25, 50];
          this.getOrdemServicos();
        }
      });
    this.getOrdemServicos();
  }

  private getOrdemServicos() {
    this.ordemServicoService
      .getAllOrdemDeServicoEncerrada(this.page, this.limit)
      .subscribe(
        (os: any) => {
          this.ordensservico = os.servicesorderclosed;
          this.limit = os.limit;
          this.totalDocs = os.totalRegister;
          this.totalPages = os.totalPages;
        },
        (error) => {
          this.toastr.error("Erro ao carregar a lista", error);
        }
      );
  }

  getFilter() {
    this.loading = true;
    this.ordemServicoService.getFilterClosed(this.filter).subscribe(
      (clientes: any) => {
        this.filterDataSource = clientes.filter((data: any) => {
          return data.cliente.nomefantasia
            .toUpperCase()
            .includes(this.filter.toUpperCase());
        });
        this.ordensservico = [...this.filterDataSource];
        let limitFilter = this.filterDataSource.length;
        this.limit = limitFilter;
        this.totalDocs = limitFilter;
        this.pageOptions = [limitFilter];
        this.page = 1;
      },
      (error) => {
        this.loading = false;
        this.toastr.error("Erro ao carregar a lista", error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  filtrar(event: any): void {
    const value = event.target.value;
    this.filter = value;
    this.searchTerms.next(this.filter);
  }

  limparFiltro() {
    this.filter = "";
    this.searchTerms.next("");
  }

  onPageChange(event: any) {
    this.limit = event.rows;
    this.page = event.page + 1;
    this.getOrdemServicos();
  }

  color(prioridade) {
    switch (prioridade) {
      case "BAIXA":
        return "badge badge-success";
      case "MÉDIA":
        return "badge badge-warning";
      case "ALTA":
        return "badge badge-danger";
    }
  }

  async gerarRelatorio(os: OrdemServico) {
    moment.locale("pt-br");
    PdfMakeWrapper.setFonts(pdfFonts);
    const pdf = new PdfMakeWrapper();

    // Informações do relatório
    pdf.info({
      title: "ALLMEDHOSP | Ordem de Servico - " + os.codigo,
      author: "Datallmed System - ALLMEDHOSP",
      subject: "Ficha detalhada da O.S.",
    });

    // Folha de estilo padrão do relatório e seus objetos.
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
    });

    // Compressão do pdf
    pdf.compress(true);

    // Definições do relatório
    pdf.pageMargins([20, 10, 20, 0]);
    pdf.pageOrientation("portrait");
    pdf.pageSize("A4");

    // header
    let imgHeader = "assets/img/header.jpg";
    pdf.header(pdf.add(await new Img(imgHeader).build()));

    pdf.add(new Canvas([new Line([555, 0], [0, 0]).end]).end);

    pdf.add(pdf.ln(1));

    pdf.add(new Txt("DADOS DO CLIENTE:").bold().end);
    pdf.add(
      new Table([
        ["NOME/RAZÃO SOCIAL", "CPF/CNPJ", "E-mail"],
        [os.cliente["nomefantasia"], os.cliente["cnpj"], os.cliente["email"]],
      ])
        .layout("noBorders")
        .widths(["*", 80, 140])
        .fontSize(8).end
    );
    pdf.add(
      new Table([
        ["Endereço", "Número", "Bairro", "Cidade", "UF", "Telefone"],
        [
          os.cliente["logradouro"],
          os.cliente["numero"],
          os.cliente["bairro"],
          os.cliente["cidade"],
          os.cliente["uf"],
          os.cliente["telefone"],
        ],
      ])
        .layout("noBorders")
        .widths(["*", 50, "*", "*", 50, 100])
        .fontSize(8).end
    );
    pdf.add(new Txt("CONTRATO:").bold().end);
    pdf.add([os.cliente["contrato"]]);

    pdf.add(pdf.ln(1));

    // Equipamentos da OS
    pdf.add(new Txt("EQUIPAMENTO:").bold().end);
    pdf.add(pdf.ln(1));

    pdf.add(
      new Table([
        ["Tipo de Equipamento", "Marca", "Modelo", "Série", "Patromônio"],
        [
          { text: os.equipamento.tipoequipamento.nome, alignment: "left" },
          { text: os.equipamento.marca, alignment: "left" },
          { text: os.equipamento.modelo, alignment: "left" },
          { text: os.equipamento.serie, alignment: "left" },
          { text: os.equipamento.patrimonio, alignment: "left" },
        ],
      ])
        .fontSize(8)
        .widths(["*", 100, 100, 100, 100]).end
    );

    pdf.add(pdf.ln(1));
    pdf.add(new Txt("DADOS DA ORDEM DE SERVIÇO:").bold().end);
    pdf.add(pdf.ln(1));

    // Dados da OS
    pdf.add(
      new Columns([
        "Num.: OS:",
        "Avaliação Técnica",
        "Data Entrada",
        "Prazo de Entrega",
      ])
        .bold()
        .alignment("left").end
    );
    pdf.add(
      new Columns([
        os.codigo,
        os.localavaliacao,
        moment(os.dataentrada).format("L"),
        os.prazoentrega,
      ]).alignment("left").end
    );

    pdf.add(pdf.ln(1));
    pdf.add(
      new Columns(["Local do Equipamento", "Status", "Prioridade"])
        .bold()
        .alignment("left").end
    );
    pdf.add(
      new Columns([os.localequipamento, os.status, os.prioridade]).alignment(
        "left"
      ).end
    );

    pdf.add(pdf.ln(1));
    pdf.add(new Txt("ESTADO GERAL DO EQUIPAMENTO:").bold().end);
    pdf.add(new Txt(os.estadoequipamento).end);

    pdf.add(pdf.ln(1));

    pdf.add(new Txt("PROBLEMA RECLAMADO:").bold().end);
    pdf.add(new Txt(os.problemareclamado).end);

    pdf.add(pdf.ln(1));
    pdf.add(new Txt("SERVIÇOS EXECUTADOS:").bold().end);
    pdf.add(pdf.ln(1));
    if (os.calibracao == true) {
      pdf.add(new Ul([pdf.add(new Txt("Calibração").end)]).end);
    }

    if (os.manutencaopreventiva == true) {
      pdf.add(new Ul([pdf.add(new Txt("Manutenção preventiva").end)]).end);
    }

    if (os.manutencaocorretiva == true) {
      pdf.add(new Ul([pdf.add(new Txt("Manutenção corretiva").end)]).end);
    }

    if (os.substituicaopeca == true) {
      pdf.add(new Ul([pdf.add(new Txt("Substituição de peças").end)]).end);
    }

    pdf.add(pdf.ln(5));
    // pdf.add(new Txt('PEÇAS SUBSTITUIDAS:').bold().end);
    // pdf.add(pdf.ln(1));
    // if (os.pecassubstituidas.length == 0) {
    //   pdf.add(new Txt('não existem peças para substituição.').end);
    // } else {
    //   const pecas: string[] = [];
    //   pecas.push(os.pecassubstituidas[0]);
    //   pecas.forEach(function (peca, i) {
    //     pdf.add(new Table([
    //       ['Descrição da Peça', 'Quantidade'],
    //       [
    //         { text: peca['descricao'], alignment: 'left' },
    //         { text: peca['quantidade'], alignment: 'left' },
    //       ]
    //     ]).widths(['*', 100]).end);
    //   });
    // }

    // pdf.add(pdf.ln(1));
    // pdf.add(new Txt('DESCRIÇÃO DO SERVIÇO REALIZADO:').bold().end);
    // pdf.add(new Txt(os.descricaoservico).end);

    pdf.add(pdf.ln(1));
    pdf.add(new Txt("SETOR / RESPONSÁVEL").bold().end);
    pdf.add(
      new Table([
        ["Setor", "Responsável"],
        [os.setor["nome"], os.user["nome"]],
      ])
        .layout("noBorders")
        .widths(["*", "*"]).end
    );

    pdf.add(pdf.ln(1));
    pdf.add(new Txt("Observações Técnicas:").bold().end);
    pdf.add(new Txt(os.observacoes).end);

    pdf.add(pdf.ln(11));
    pdf.footer([
      pdf.add(
        new Txt(
          "Empreendimentos Allmed Clyhosp Comércio e Assistência Técnica em Equipamentos Ltda."
        )
          .bold()
          .fontSize(8)
          .alignment("center").end
      ),
      pdf.add(
        new Txt(
          "Laboratórios Técnicos: Rua Comendador Bastos n. 10, Mares, Salvador-BA"
        )
          .fontSize(8)
          .alignment("center").end
      ),
      pdf.add(
        new Txt("Telefones: (71) 3314-1680 / 3561-9679")
          .fontSize(8)
          .alignment("center").end
      ),
      pdf.add(
        new Txt("E-mail: allmedhosp@allmedhosp.com.br")
          .fontSize(8)
          .alignment("center").end
      ),
      pdf.add(
        new Txt("Site: http://allmedhosp.com.br")
          .fontSize(8)
          .alignment("center").end
      ),
      pdf.add(
        new Txt("O.S. - ALLMEDHOSP - Data: 22/09/2020 - Rev.: 03")
          .fontSize(8)
          .alignment("right").end
      ),
    ]);

    pdf.create().download("OS_" + os.codigo + ".pdf");
  }
}
