import { Component, OnInit } from "@angular/core";

import { ClienteService } from "../cliente/cliente.service";
import { OrdemServico } from "../models/ordemservico.model";
import { OrdemServicoService } from "../ordemservico/ordemservico.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  totalOSAbertas: number;

  totalOSEncerradas: number;

  totalClientes: number;

  ordensservicos: OrdemServico[] = [];

  constructor(
    private osService: OrdemServicoService,
    private clienteService: ClienteService
  ) {}

  ngOnInit() {
    this.getClientes();
    this.getOrdensServicos();
  }

  getOrdensServicos() {
    this.osService.getAll().subscribe((ordensservicos: any) => {
      this.totalOSAbertas = ordensservicos.totalRegister;
    });

    this.osService.getAllClosed().subscribe((ordensservicos: any) => {
      this.totalOSEncerradas = ordensservicos.totalRegister;
    });
  }

  getClientes() {
    this.clienteService.getAll().subscribe((clientes: any) => {
      this.totalClientes = clientes.totalRegister;
    });
  }
}
