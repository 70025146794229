import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { OsEncerradaComponent } from './os-encerrada/os-encerrada.component';

import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'os-encerrada', component: OsEncerradaComponent },
  { path: 'departamentos', loadChildren: () => import('./departamentos/departamentos.module').then(m => m.DepartamentosModule), canActivate: [AuthGuard] },
  { path: 'tiposclientes', loadChildren: () => import('./tiposclientes/tiposclientes.module').then(m => m.TiposclientesModule), canActivate: [AuthGuard] },
  { path: 'tipoequipamento', loadChildren: () => import('./tipoequipamento/tipoequipamento.module').then(m => m.TipoequipamentoModule), canActivate: [AuthGuard] },
  { path: 'funcionarios', loadChildren: () => import('./funcionario/funcionario.module').then(m => m.FuncionarioModule), canActivate: [AuthGuard] },
  { path: 'clientes', loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule), canActivate: [AuthGuard] },
  { path: 'ordemservico', loadChildren: () => import('./ordemservico/ordemservico.module').then(m => m.OrdemservicoModule), canActivate: [AuthGuard] },
  { path: 'setor', loadChildren: () => import('./setores/setores.module').then(m => m.SetoresModule), canActivate: [AuthGuard] },
  { path: 'manutencoes', loadChildren: () => import('./manutencoes/manutencoes.module').then(m => m.ManutencoesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
