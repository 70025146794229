import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "../../environments/environment";

import { Cliente } from "../models/cliente.model";

@Injectable({ providedIn: "root" })
export class ClienteService {
  private apiPath = `${environment.DATALLMED_API}/clientes`;

  constructor(private _http: HttpClient) {}

  public getAll(): Observable<Cliente[]> {
    return this._http.get(this.apiPath).pipe(catchError(this.handleError));
  }

  public getAllClientes(page?: number, limit?: number): Observable<Cliente[]> {
    let url = `${this.apiPath}?page=${page}&limit=${limit}`;
    return this._http.get(url).pipe(catchError(this.handleError));
  }

  public getFilter(value?: string): Observable<Cliente[]> {
    const params = new HttpParams().append("value", value.toUpperCase());
    return this._http
      .get(`${this.apiPath}/search`, { params })
      .pipe(catchError(this.handleError));
  }

  public getById(_id: string): Observable<Cliente> {
    const url = `${this.apiPath}/${_id}`;
    return this._http
      .get(url)
      .pipe(catchError(this.handleError), map(this.jsonDataToCliente));
  }

  public create(cliente: Cliente): Observable<Cliente> {
    return this._http
      .post(this.apiPath, cliente)
      .pipe(catchError(this.handleError), map(this.jsonDataToCliente));
  }

  public update(cliente: Cliente): Observable<Cliente> {
    const url = `${this.apiPath}/${cliente._id}`;
    return this._http
      .patch(url, cliente)
      .pipe(catchError(this.handleError), map(this.jsonDataToCliente));
  }

  public delete(_id: string): Observable<any> {
    const url = `${this.apiPath}/${_id}`;
    return this._http.delete(url).pipe(
      catchError(this.handleError),
      map(() => null)
    );
  }

  private jsonDataToClientes(jsonData: any[]): Cliente[] {
    const clientes: Cliente[] = [];
    jsonData.forEach((element) => clientes.push(element as Cliente));
    return clientes;
  }

  private jsonDataToCliente(jsonData: any): Cliente {
    return jsonData as Cliente;
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\n Message: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
