<app-header></app-header>
<div class="card">
  <div class="d-flex justify-content-end mb-3">
    <span class="p-input-icon-left ml-auto">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        aria-label="Filtrar por nome"
        (keyup)="filtrar($event)"
        (keydown.escape)="limparFiltro()"
        placeholder="Buscar cliente"
        class="text-uppercase"
      />
    </span>
  </div>

  <p-table
    *ngIf="!loading"
    #dt1
    styleClass="p-datatable-striped"
    [value]="ordensservico"
    [rows]="limit"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="codigo" style="width: 8%; text-align: center">
          Código <p-sortIcon field="codigo"></p-sortIcon>
        </th>
        <th
          pSortableColumn="cliente.nomefantasia"
          style="width: 25%; text-align: left"
        >
          Cliente <p-sortIcon field="cliente.nomefantasia"></p-sortIcon>
        </th>
        <th
          pSortableColumn="equipamento.tipoequipamento.nome"
          style="width: 25%; text-align: left"
        >
          Equipamento
          <p-sortIcon field="equipamento.tipoequipamento.nome"></p-sortIcon>
        </th>
        <th pSortableColumn="user.nome" style="width: 15%; text-align: left">
          Responsável <p-sortIcon field="user.nome"></p-sortIcon>
        </th>
        <th
          pSortableColumn="dataentrada"
          style="width: 15%; text-align: center"
        >
          Data Entrada <p-sortIcon field="dataentrada"></p-sortIcon>
        </th>
        <th pSortableColumn="updatedAt" style="width: 15%; text-align: center">
          Data Encerramento <p-sortIcon field="updatedAt"></p-sortIcon>
        </th>
        <th pSortableColumn="prioridade" style="width: 10%; text-align: center">
          Prioridade <p-sortIcon field="prioridade"></p-sortIcon>
        </th>
        <th style="width: 5%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-os>
      <tr>
        <td style="text-align: center">
          {{ os.codigo }}
        </td>
        <td style="text-align: left">{{ os.cliente["nomefantasia"] }}</td>
        <td style="text-align: left">
          {{ os.equipamento.tipoequipamento.nome }}
        </td>
        <td style="text-align: left">{{ os.user["nome"] }}</td>
        <td style="text-align: center">
          {{ os.dataentrada | date : "dd/MM/yyyy HH:mm:ss" }}
        </td>
        <td style="text-align: center">
          {{ os.updatedAt | date : "dd/MM/yyyy HH:mm:ss" }}
        </td>
        <td style="text-align: center">
          <p style="margin-bottom: 0">
            <small
              class="d-flex justify-content-center align-items-center"
              [class]="color(os.prioridade)"
              style="font-size: 12px; width: 100%; height: 36px"
              >{{ os.prioridade }}</small
            >
          </p>
        </td>
        <td>
          <button
            (click)="gerarRelatorio(os)"
            class="btn btn-block btn-primary"
          >
            <i class="fas fa-print"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    *ngIf="!loading"
    [rows]="limit"
    [totalRecords]="totalDocs"
    [first]="(page - 1) * limit"
    (onPageChange)="onPageChange($event)"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} até {last} de {totalRecords} registros"
    [rowsPerPageOptions]="pageOptions"
  ></p-paginator>
  <div *ngIf="loading" class="loading-indicator">
    <i class="pi pi-spin pi-spinner"></i>
  </div>
</div>
<app-footer></app-footer>
